
import router from "@/router";
import { defineComponent, ref, reactive, onMounted, inject, watch } from "vue";
import { login } from '../api/research/index'
import { ElMessage } from "element-plus";
export default defineComponent({

    setup() {
        var code: any = ref('')
        var state: any = ref('')
        // let url = 'http://ai.dic.cool/#/login'
        let url = window.location.origin + '/#/login'
        onMounted(() => {
            const wwLogin = new window.WwLogin({
                id: "wx_reg",
                appid: "wx6f392ac139fdd4e4",
                agentid: "1000022",
                redirect_uri: encodeURIComponent(url),
                // redirect_uri: 'http://192.168.66.86:8083/#/login',
                state: encodeURIComponent(window.location.origin),
                // state:'http://192.168.66.86:8083',
                href: "data:text/css;base64,LmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gCi5pbXBvd2VyQm94IC50aXRsZXsgZGlzcGxheTogbm9uZTt9",
                lang: "zh",
            });
        })
        const submitLogin = () => {
            console.log(code.value, state.value)
            login({ code: code.value }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    localStorage.setItem('token', res.data.data.token)
                    localStorage.setItem('username', res.data.data.name)
                    ElMessage.success('登录成功')
                    router.push({ path: '/' })
                }
            })
        }
        // vue3监听路由变化
        watch(
            () => router.currentRoute.value,
            (to, from) => {
                console.log(to, from)
                code.value = to.query.code
                // if(to.query.state){
                //     state.value = decodeURIComponent(to.query.state)
                // }
                if (to.query.code) {
                    submitLogin()
                }
            }
        )
    }
});

