import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import qr from '../views/qr.vue'
import xcqr from '../views/xcqr.vue'
import pyqr from '../views/pyqr.vue'
import login from '../views/login.vue' 
import question from '../views/question.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/qr',
    name: 'qr',
    component: qr
  },
  {
    path: '/xcqr',
    name: 'xcqr',
    component: xcqr
  },
  {
    path: '/pyqr',
    name: 'pyqr',
    component: pyqr
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/question',
    name: 'question',
    component: question
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
