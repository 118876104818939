
  import { defineComponent, ref, reactive,onMounted } from "vue";
  import { getmodellist,txt2img } from '@/api/research/qrxcindex'
  export default defineComponent({
    name: "Qr",
    components: {},
    setup() {
      var tasklist = ref ({})
      var task_id = ref(0)
      var loading = ref(false);
      var inputfile = ref(null);
      var imagebase = ref("");
      var input = ref("");
      var nvinput = ref("");
      var num = ref(1);
      var restore_faces = ref(true);
      var enable_hr = ref(true);
      var modelist = ref([])
      var seed = ref(-1)
        var cfg_scale = ref(7)
        var steps = ref(30)
        var controlweight = ref(0.45)
        var controlweight2 = ref(0.6)
        var guidance_start = ref(0.24)
        var guidance_end = ref(0.83)
        var guidance_start2 = ref(0.3)
        var guidance_end2 = ref(0.75)
      var sd_model_checkpoint = ref('anything-v5-PrtRE.safetensors [7f96a1a9ca]') // 模型名称
      var imagesdata = ref([])
        onMounted(() => {
            getmodellist().then(res=>{
                modelist.value = res.data
                console.log(modelist.value);
                
            })
        })
      const change = (e: any) => {
        console.log(e.target.files[0]);
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e: any) {
          imagebase.value = e.target.result;
        };
      };
      const aset = () => {
        loading.value = true;
          txt2img({
                height:712,
                width:712,
                prompt: input.value,
                negative_prompt: nvinput.value,
                restore_faces: restore_faces.value,
                // enable_hr: enable_hr.value,
                n_iter:1,
                batch_size:num.value,
                seed:seed.value,
                cfg_scale:cfg_scale.value,
                steps:steps.value,
                tiling:false,
                sampler_index:'DPM++ 2M Karras',
                override_settings:{
                    sd_model_checkpoint:sd_model_checkpoint.value
                },
                alwayson_scripts:{
                    controlnet:{
                        args:[
                            {
                                input_image:imagebase.value,
                                model:'control_v1p_sd15_qrcode_monster [a6e58995]',
                                weight:controlweight.value,
                                resize_mode:2,
                                guidance_start:guidance_start.value,
                                guidance_end:guidance_end.value,
                                pixel_perfect:true,
                            },
                            {
                                input_image:imagebase.value,
                                model:'control_v1p_sd15_brightness [5f6aa6ed]',
                                weight:controlweight2.value,
                                resize_mode:2,
                                guidance_start:guidance_start2.value,
                                guidance_end:guidance_end2.value,
                                pixel_perfect:true,
                            }
                        ]
                    }
                }
          }).then(res=>{
            console.log(res);
            imagesdata.value = res.data.images
            loading.value = false;
          })
        //   quwei_qr({
        //       prompt: input.value,
        //       input_image: imagebase.value,
        //       batch_size: num.value,
        //       restore_faces: restore_faces.value,
        //       enable_hr: enable_hr.value,
        //       hr_scale:1.5
        //   }).then(res=>{
        //       console.log(res);
        //       if(res.data.code == 0){
        //           // tasklist.value = res.data.data
        //           task_id.value = res.data.data.task_id
        //           setTimeout(() => {
        //               gettaskstatus()
        //           }, 5000);
        //       }
        //   })
      };
      const aaa = (e:any) => {
        console.log(e);
        
      }
      return {
        inputfile,
        imagebase,
        input,
        nvinput,
        num,
        restore_faces,
        enable_hr,
        tasklist,
        task_id,
        modelist,
        sd_model_checkpoint,
        seed,
        cfg_scale,
        steps,
        imagesdata,
        loading,
        controlweight,
        controlweight2,
        guidance_start,
        guidance_end,
        guidance_start2,
        guidance_end2,
        aaa,
        change,
        aset,
      };
    },
  });
  