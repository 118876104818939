
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue';
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import $ from 'jquery';
import { marked } from 'marked';
import router from "@/router";
import hljs from 'highlight.js'
import 'highlight.js/styles/foundation.css'
import { ElMessage, valueEquals } from 'element-plus'
const render = new marked.Renderer()
marked.setOptions({
    renderer: render, // 这是必填项
    gfm: true,	// 启动类似于Github样式的Markdown语法
    pedantic: false, // 只解析符合Markdwon定义的，不修正Markdown的错误
    sanitize: false, // 原始输出，忽略HTML标签（关闭后，可直接渲染HTML标签）
    // 高亮的语法规范
    highlight: (code, lang) => {
        if (typeof lang === 'undefined') {
            return hljs.highlight(code, { language: 'javascript' }).value;
        }
        return hljs.highlight(code, { language: lang ? lang : 'javascript' }).value;
    }
})
export default defineComponent({
    name: 'HomeView',
    components: {
        // HelloWorld,
    },

    setup() {
        var modelname = ref('Assistant');
        var check = ref(0);
        var input = ref('');
        var qusitionid = ref('');
        var showBlinkCursor = ref(false);
        var returnMessageAjax:any = null
        var isinline = ref(false);
        var num = 0;
        var showSide = ref(true);
        var showBtn = ref(false);
        var pageWidth = ref(0);
        var toolImage = ref('')
        var chatlist: any = ref([
        ])
        var username = ref(localStorage.getItem('username'))
        const inputText = ref<any>(null);
        var navemessage = ref('')
        const modeltyps = reactive([
            // {
            //     type: 'GPT-4',
            //     name: 'ChatCopilot-gpt4',
            //     image: require('../assets/img/openAIBlue.webp'),  
            // },
            {
                type: 'GPT-4-Classic',
                name: 'GPT-4-Classic',
                image: require('../assets/img/openAIBlue.webp'),  
            },
            {
                type: 'capybara',
                name: 'Assistant',
                image: require('../assets/img/main-qimg-e4a356b7683dbb27559361caa79a1af4.webp'),
            },
            {
                type: 'a2_100k',
                name: 'Claude-instant-100k',
                image: require('../assets/img/anthropicAvatarBeigeBeta.webp'),
            },
            {
                type: 'Claude-2.1-200k',
                name: 'Claude-2.1-200k',
                image: require('../assets/img/anthropicAvatarBeigeBeta.webp'),
            },
            {
                type: 'Claude-2',
                name: 'Claude-2',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'a2_2',
                name: 'Claude-2-100k',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'Claude-3-Sonnet',
                name: 'Claude-3-Sonnet',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'Claude-3-Haiku',
                name: 'Claude-3-Haiku',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'Claude-3-Opus',
                name: 'Claude-3-Opus',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'Claude-3-Sonnet-200k',
                name: 'Claude-3-Sonnet-200k',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'Claude-3-Haiku-200k',
                name: 'Claude-3-Haiku-200k',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'Claude-3-Opus-200k',
                name: 'Claude-3-Opus-200k',
                image: require('../assets/img/anthropicAvatarBrown.webp'),
            },
            {
                type: 'a2',
                name: 'Claude-instant',
                image: require('../assets/img/anthropicAvatarBeige.webp'),
            },
            {
                type: 'chinchilla',
                name: 'ChatGPT',
                image: require('../assets/img/chatGPTAvatar.webp'),
            },
            {
                type: 'agouti',
                name: 'ChatGPT-16k',
                image: require('../assets/img/main-qimg-1569b6c5f28e1a2d5b1f821a8a8d27ec.webp'),
            },
            {
                type: 'GPT-3.5-Turbo',
                name: 'GPT-3.5-Turbo',
                image: require('../assets/img/chatGPTAvatar.webp'),
            },
            {
                type: 'beaver',
                name: 'GPT-4',
                image: require('../assets/img/openAIBlue.webp'),
            },
            {
                type: 'vizcacha',
                name: 'GPT-4-32k',
                image: require('../assets/img/main-qimg-3f53030558570aeb0bf0e8fcd483b464.webp'),
            },
            {
                type: 'GPT-4-128k',
                name: 'GPT-4-128k',
                image: require('../assets/img/main-qimg-3f53030558570aeb0bf0e8fcd483b464.webp'),
            },
            {
                type: 'acouchy',
                name: 'Google-PaLM',
                image: require('../assets/img/googlePalmAvatar.5ca326b0.webp'),
            },
            {
                type: 'Mixtral-8x7b-Groq',
                name: 'Mixtral-8x7b-Groq',
                image: require('../assets/img/googlePalmAvatar.5ca326b0.webp'),
            },
            {
                type: 'Gemma-Instruct-7B-T',
                name: 'Gemma-Instruct-7B-T',
                image: require('../assets/img/googlePalmAvatar.5ca326b0.webp'),
            },
            {
                type: 'Gemma-7b-FW',
                name: 'Gemma-7b-FW',
                image: require('../assets/img/googlePalmAvatar.5ca326b0.webp'),
            },
            {
                type: 'llama_2_7b_chat',
                name: 'Llama-2-7b',
                image: require('../assets/img/main-qimg-1d11e557a479a27af03865d46bc8bb41.webp'),
            },
            {
                type: 'llama_2_13b_chat',
                name: 'Llama-2-13b',
                image: require('../assets/img/main-qimg-4803f5d0fdcbd0cfe34eeb101bb5a44f.webp'),
            },
            {
                type: 'llama_2_70b_chat',
                name: 'Llama-2-70b',
                image: require('../assets/img/main-qimg-847f5b3cd2c01c863ee20deb9f33c5a1.webp'),
            },
            {
                type: 'Llama-2-70b-Groq',
                name: 'Llama-2-70b-Groq',
                image: require('../assets/img/main-qimg-847f5b3cd2c01c863ee20deb9f33c5a1.webp'),
            },
            {
                type: 'RekaFlash',
                name: 'RekaFlash',
                image: require('../assets/img/main-qimg-847f5b3cd2c01c863ee20deb9f33c5a1.webp'),
            },
            {
                type: 'DALL-E-3',
                name: 'DALL-E-3',
                image: require('../assets/img/main-thumb-pb-2828029-200-mlvmbysbpfpcjfyxswvxbzufpvszchqb.jpg'), 
            },
            {
                type: 'StableDiffusionXL',
                name: 'StableDiffusionXL',
                image: require('../assets/img/sd.jpg'), 
            },
            
            {
                type: 'Web-Search',
                name: 'Web-Search',
                image: require('../assets/img/websearch.jpg'), 
            },
            {
                type: 'Playground-v2',
                name: 'Playground-v2',
                image: require('../assets/img/play.jpg'), 
            },
            {
                type: 'Mistral-Large',
                name: 'Mistral-Large',
                image: require('../assets/img/mistral.jpg'), 
            },
            {
                type: 'Mistral-Medium',
                name: 'Mistral-Medium',
                image: require('../assets/img/mistral.jpg'), 
            },
            {
                type: 'Gemini-Pro',
                name: 'Gemini-Pro',
                image: require('../assets/img/gemini.jpg'), 
            },
            {
                type: 'Mixtral-8x7B-Chat',
                name: 'Mixtral-8x7B-Chat',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'DeepSeek-Coder-33B-T',
                name: 'DeepSeek-Coder-33B-T',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'Code-Llama-7b',
                name: 'Code-Llama-7b',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'Code-Llama-13b',
                name: 'Code-Llama-13b',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'Code-Llama-34b',
                name: 'Code-Llama-34b',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'Code-Llama-70B-FW',
                name: 'Code-Llama-70B-FW',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'CodeLlama-70B-T',
                name: 'CodeLlama-70B-T',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'fw-mistral-7b',
                name: 'fw-mistral-7b',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'MythoMax-L2-13B',
                name: 'MythoMax-L2-13B',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'Qwen-72b-Chat',
                name: 'Qwen-72b-Chat',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'Qwen-72B-T',
                name: 'Qwen-72B-T',
                image: require('../assets/img/fw.jpg'), 
            },
            {
                type: 'gpt3.5',
                name: 'ChatHi-gpt3.5',
                image: require('../assets/img/chatGPTAvatar.webp'), 
            },
            {
                type: 'GPT-3.5-Turbo-Instruct',
                name: 'GPT-3.5-Turbo-Instruct',
                image: require('../assets/img/chatGPTAvatar.webp'), 
            },
            {
                type: 'gpt4',
                name: 'ChatHi-gpt4',
                image: require('../assets/img/openAIBlue.webp'), 
            },
            {
                type: 'gpt4-联网版',
                name: 'ChatHi-gpt4-联网版',
                image: require('../assets/img/openAIBlue.webp'), 
            },
            {
                type: 'gpt4-绘画版',
                name: 'ChatHi-gpt4-绘画版',
                image: require('../assets/img/openAIBlue.webp'), 
            },
            {
                type: 'gpt4-turbo-128k',
                name: 'ChatHi-gpt4-turbo-128k',
                image: require('../assets/img/openAIBlue.webp'), 
            },
            {
                type: 'Claude',
                name: 'ChatHi-Claude',
                image: require('../assets/img/anthropicAvatarBeigeBeta.webp'), 
            },
            {
                type: 'Solar-Mini',
                name: 'Solar-Mini',
                image: require('../assets/img/gemini.jpg'), 
            },
            {
                type: 'Gemini-pro',
                name: 'ChatHi-Gemini-pro',
                image: require('../assets/img/gemini.jpg'), 
            },
            {
                type: 'LLaMa2',
                name: 'ChatHi-LLaMa2',
                image: require('../assets/img/main-qimg-1d11e557a479a27af03865d46bc8bb41.webp'), 
            },
            {
                type: 'Playground-v2.5',
                name: 'Playground-v2.5',
                image: require('../assets/img/main-qimg-1d11e557a479a27af03865d46bc8bb41.webp'), 
            },
            {
                type: '百川智能',
                name: 'ChatHi-百川智能',
                image: require('../assets/img/百川.png'), 
            },
           
            // {
            //     type: 'llama_2_70b_chat',
            //     name: 'AI二维码',
            //     image: require('../assets/img/qrcode.png'),
            // },
            // {
            //     type: 'llama_2_70b_chat',
            //     name: 'AI二维码2.0',
            //     image: require('../assets/img/qrcode1.png'),
            // },
            
            {
                type: 'Atdraw',
                name: 'AI绘画',
                image: require('../assets/img/aidraw.png'), 
            },
        ])
        var chatlisthistory:any = ref({})
        var file = ref<any>(null);
        const tabs = (index: number, type: string, img: string) => {
            stop_()
            check.value = index;
            modelname.value = type;
            toolImage.value = img;
            chatlist.value = chatlisthistory.value[type]?chatlisthistory.value[type]:[];
            qusitionid.value = chatlisthistory.value[type]?chatlisthistory.value[type][chatlisthistory.value[type].length-1].chatId:'';
            scrollBottm('div');
            if(type == 'AI二维码'){
              router.push({ path: '/qr' })
            }
            if(type == 'AI二维码2.0'){
              router.push({ path: '/xcqr' })
            }
            if(type == 'AI绘画'){
              window.open('http://aidraw.dic.cool/#/login?token=' + localStorage.getItem('token')+'&username='+ localStorage.getItem('username'), '_blank')
            }
        }
        const confirmEvent = () => {
            localStorage.removeItem('token')
            router.push('/login')
        }
        const inputfile = () => {
            // 创建input选择文件
            const input = document.createElement('input');
            input.type = 'file';
            // input.accept = 'image/*';
            input.click();
            var filetype:any =  {
                '.pdf': 'application/pdf',
                '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                '.txt': 'text/plain',
                '.py': 'text/x-python',
                '.js': 'text/javascript',
                '.ts': 'text/plain',
                '.html': 'text/html',
                '.css': 'text/css',
                '.csv': 'text/csv',
                '.c': 'text/plain',
                '.cs': 'text/plain',
                '.cpp': 'text/plain',
            }
            // 监听文件选择
            input.onchange = (e: any) => {
                // 如果文件类型不对
                if (!filetype['.'+e.target.files[0].name.split('.').pop()]) {

                    ElMessage({
                        message: '文件类型不支持',
                        type: 'warning'
                    });
                    return
                }
                // 如果文件大小超过10M
                if (e.target.files[0].size > 1024 * 1024 * 100) {
                    ElMessage({
                        message: '文件大小超过100M',
                        type: 'warning'
                    });
                    return
                }
                file.value = e.target.files[0];
                console.log(file.value);
                
                
            };
        }
        const sendmessage = (type: string, c: number) => {
            if (showBlinkCursor.value == true) {
                ElMessage({
                    message: '请等待上一次问答结束',
                    type: 'warning'
                });
                return
            }
            var message = ''
            showBlinkCursor.value = true;
            if (type == 'restart' && c < 2) {
                scrollBottm('div')
                getanwser(navemessage.value)
            } else if (input.value) {
                let text = input.value
                text = text.replace(/\n/g, '<br/>')
                // 将空格转化为&nbsp;
                text = text.replace(/\s/g, '&nbsp;')
                chatlist.value.push({
                    type: 'user',
                    content: text,
                    filename: file.value?file.value.name:'',
                },
                    {
                        type: 'bot',
                        content: '',
                        chatId: qusitionid.value?qusitionid.value:'',
                        
                    })
                navemessage.value = input.value;
                message = input.value;
                input.value = '';
                inputText.value.style.height = '30px';
                inputText.value.style.overflowY = "auto";
                getanwser(message)
                scrollBottm(null)
            }
            
        }
        const getanwser = (message: string) => {
            var modelnames:any = ''
            var url:any = ''
            console.log(modelname.value);
            var data:any = new FormData();
            var headers:any = {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                // 'Content-Type': 'application/json'
            } 
            if(modelname.value.indexOf('ChatHi') != -1){
                modelnames = modelname.value.split('hatHi-')[1]
                
                url =  "http://ai.dic.cool/api_chathi/AI" 
                data = {
                    message: message,
                    Type: modelnames,
                }
                if(qusitionid.value){
                    data.session_id = qusitionid.value;
                }
                data = JSON.stringify(data)
                headers['Content-Type'] = 'application/json'
            }else if(modelname.value.indexOf('ChatCopilot') != -1){
                modelnames = modelname.value.split('hatCopilot-')[1]
                var messages = []
                if(chatlisthistory.value[modelname.value]){
                    chatlisthistory.value[modelname.value].forEach((element:any,index:any) => {
                        // 值传入最后四条
                        if(index > chatlisthistory.value[modelname.value].length - 5){
                            if(element.type == 'user'){
                                messages.push({
                                    role: element.type,
                                    content: element.content,
                                })
                            }else{
                                messages.push({
                                    role: 'assistant',
                                    content: element.content,
                                })
                            }
                        }
                    });
                }
                messages.push({
                    role: 'user',
                    content: message,
                })
                url =  "http://ai.dic.cool/api_chatcopilot/AI" 
                data = {
                    message: messages,
                    Type: 'GPT-4',
                }
                if(qusitionid.value){
                    data.session_id = qusitionid.value;
                }
                data = JSON.stringify(data)
                headers['Content-Type'] = 'application/json'
            }else{
                modelnames = modelname.value
                url = 'http://ai.dic.cool/api_v2/AI'
                data.append('message', message);
                data.append('Type', modelnames);
                if(qusitionid.value){
                    data.append('chatId', qusitionid.value);
                }
                if(file.value){
                    data.append('file', file.value);
                }
            }
            
           
            
            file.value = null;
            
           
            returnMessageAjax = $.ajax({
                // url:'http://192.168.100.207/api_v2/AI',
                // url: window.location.origin + '/api_v2/AI',
                url: url,
                contentType:false,
                processData:false,  
                timeout: 500000,
                type: 'post',

                headers:headers,
                data: data,
                xhrFields: {
                    onprogress: function (e: any) {
                        console.log(e);
                        
                        let response = e.currentTarget.response;
                        
                        // console.log(response);
                        if (e.currentTarget.status == 404) {
                            chatlist.value[chatlist.value.length - 1].content = '处理失败'
                            showBlinkCursor.value = false;
                            returnMessageAjax?.abort();
                            stop_();
                            return
                        }
                        // // 更据换行分割成数组
                        let responseArr = response.split('|+|+|+|');
                        // // 获取最后一条数据
                        console.log(responseArr);
                        
                        let lastData = responseArr[responseArr.length - 1];
                        if(lastData == ''){
                            lastData = responseArr[responseArr.length - 2];
                        }
                        // console.log(JSON.parse(lastData));
                        
                        // if(JSON.parse(lastData).code == 400){
                        //   //重试
                        //   if(num == 2){
                        //     num = 0
                        //     chatlist.value[chatlist.value.length-1].content = '处理失败'
                        //   }else{
                        //       // 重试
                        //     num += 1; 
                        //     sendmessage('restart',num);
                        //   }
                        // }else{

                            
                        if (response.indexOf(`"code":400`) != -1) {
                            var json = JSON.parse(response);
                            if (json.code == 400) {
                                //重试
                                if (num == 2) {
                                    num = 0
                                    chatlist.value[chatlist.value.length - 1].content = json.msg
                                } else {
                                    // 重试
                                    num += 1;
                                    sendmessage('restart', num);
                                }
                            }
                        }
                        try {
                            if(modelname.value.indexOf('ChatHi') != -1 || modelname.value.indexOf('ChatCopilot') != -1){
                                
                                var text = ''
                                responseArr.forEach((element:any) => {
                                    if(element){
                                        text += JSON.parse(element).text
                                        qusitionid.value = JSON.parse(element).session_id;
                                    }
                                });
                                addanswer(text);
                                scrollBottm('div')
                            }else{
                                if(JSON.parse(lastData)){
                                    qusitionid.value = JSON.parse(lastData).chatId;
                                    addanswer(JSON.parse(lastData).text);
                                    scrollBottm('div')
                                }
                            }
                            
                        } catch (error) {
                            console.log(error);
                            
                        }
                       
                       
                        // }
                        // addanswer(JSON.parse(lastData).text);
                        // scrollBottm('div')
                    }
                },
                complete: function (XMLHttpRequest: any, status: string) {
                    console.log(XMLHttpRequest);
                    if (XMLHttpRequest.responseJSON) {
                        if (XMLHttpRequest.responseJSON.code == 1000 || XMLHttpRequest.responseJSON.code == 1002 || XMLHttpRequest.responseJSON.code == 1001) {
                            ElMessage({
                                message: '登录失效，请重新登录',
                                type: 'warning'
                            });
                            localStorage.removeItem('token')
                            router.push('/login')
                            return
                        }
                    }
                    // input.value = '';
                    // return
                    stop_();
                },
            });
        }
        const addanswer = (message: string) => {
            chatlist.value[chatlist.value.length - 1].content = marked(message)
            chatlisthistory.value[modelname.value] = chatlist.value
            localStorage.setItem('chatlisthistory', JSON.stringify( chatlisthistory.value))
        }
        const stop_ = () => {
            if (returnMessageAjax !== null) {
                returnMessageAjax.abort();
            }
            showBlinkCursor.value = false;
        }
        const isScrollbarAtBottom = () => {
            const element = window.document.getElementById("div");
            if (!element) {
                return false;
            }
            var scrollHeight = element.scrollHeight;
            var clientHeight = element.clientHeight;
            var scrollTop = element.scrollTop;
            return scrollTop === scrollHeight - clientHeight;
        }
        const scrollBottm = (e: any) => {
            if (e) {
                if (isScrollbarAtBottom()) {
                    setTimeout(function () {
                        const el = window.document.getElementById("div");
                        if (el && el.scrollHeight) {
                            el.scrollTop = el.scrollHeight + 100;
                        }
                    }, 50);
                }
            } else {
                setTimeout(function () {
                    const el = window.document.getElementById("div");
                    if (el && el.scrollHeight) {
                        el.scrollTop = el.scrollHeight + 100;
                    }
                }, 50);
            }
        }
        const showSideBar = () => {
            showSide.value = !showSide.value;
        }
        const changeTools = (type: string, img: string) => {
            modelname.value = type;
            toolImage.value = img;
        }

        const keydown = (event: any) => {
            if (event.key === 'Enter') {
                if (event.ctrlKey) {
                // 用户按下了 Ctrl + Enter 组合键
                    input.value += '\n'
                    const length = input.value.split('\n').length
                    const scrollHeight = inputText.value.scrollHeight;
                    // inputText.value.style.height = `${length * 25 + scrollHeight}px`;
                    // 滚动条滚动到底部
                    nextTick(() => {
                        inputText.value.style.height = `${scrollHeight + 25}px`
                        inputText.value.scrollTop = scrollHeight;
                    });
                } else {
                    // 用户按下了单独的 Enter 键
                    event.preventDefault()
                    sendmessage('none', 0)
                }
            }
        }
        function handleInput(e: any){
            e.target.style.height = 'auto'
            e.target.style.height = `${e.target.scrollHeight+2}px`
        }
        onMounted(() => {
            if (!localStorage.getItem('token')) {
                ElMessage({
                    message: '登录失效，请重新登录',
                    type: 'warning'
                });
                router.push('/login')
                return
            }
            // 页面初始化
            if(localStorage.getItem('chatlisthistory')){
                chatlisthistory.value = JSON.parse(localStorage.getItem('chatlisthistory') || '{}')
                chatlist.value = chatlisthistory.value[modelname.value]?chatlisthistory.value[modelname.value]:[]
                qusitionid.value = chatlisthistory.value[modelname.value]?chatlisthistory.value[modelname.value][chatlisthistory.value[modelname.value].length-1].chatId:''
                scrollBottm('div')
            }

            modelname.value = 'Assistant'
            toolImage.value = modeltyps[0].image
            // 页面初始化根据视口宽度判断是否展示侧边栏及列表图标
            pageWidth.value = document.body.clientWidth;
            if (pageWidth.value > 1200) {
                showSide.value = true;
                showBtn.value = false;
            } else {
                showSide.value = false;
                showBtn.value = true;
            }
            // 监听页面宽度 当页面宽度变化小于1200时，隐藏侧边栏
            window.addEventListener('resize', () => {
                pageWidth.value = document.body.clientWidth;
                if (pageWidth.value > 1200) {
                    showSide.value = true;
                    showBtn.value = false;
                } else {
                    showSide.value = false;
                    showBtn.value = true;
                }
            })

            // 监听鼠标点击事件，点击非sideBar时，隐藏侧边栏
            const sideBar = document.querySelector('.center_left')
            const sideBtn = document.querySelector('.list_img')
            document.addEventListener('click', (e) => {
                if (pageWidth.value < 1200) {
                    if (e.target !== sideBar && !sideBar?.contains(e.target as Node) && e.target !== sideBtn && !sideBtn?.contains(e.target as Node)) {
                        showSide.value = false;
                    }
                }
            })

        })
        return {
            showSide,
            showBtn,
            username,
            showSideBar,
            pageWidth,
            confirmEvent,
            toolImage, // 头部工具图标
            keydown,
            changeTools,
            modelname,
            modeltyps,
            inputfile,
            check,
            input,
            isinline,
            chatlist,
            file,
            showBlinkCursor,
            tabs,
            sendmessage,
            inputText,
            handleInput
        };
    },
});
