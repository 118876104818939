
import { defineComponent, ref, reactive } from "vue";
import { quwei_qr,progress } from '@/api/research/qrindex'
export default defineComponent({
  name: "Qr",
  components: {},
  setup() {
    var tasklist = ref ({})
    var task_id = ref(0)
    var inputfile = ref(null);
    var imagebase = ref("");
    var input = ref("");
    var num = ref(1);
    var restore_faces = ref(true);
    var enable_hr = ref(true);
    const change = (e: any) => {
      console.log(e.target.files[0]);
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e: any) {
        imagebase.value = e.target.result;
      };
    };
    const aset = () => {
        console.log(input.value);
        console.log(imagebase.value);
        console.log(num.value);
        console.log(restore_faces.value);
        console.log(enable_hr.value);
        quwei_qr({
            prompt: input.value,
            input_image: imagebase.value,
            batch_size: num.value,
            restore_faces: restore_faces.value,
            enable_hr: enable_hr.value,
            hr_scale:1.5
        }).then(res=>{
            console.log(res);
            if(res.data.code == 0){
                // tasklist.value = res.data.data
                task_id.value = res.data.data.task_id
                setTimeout(() => {
                    gettaskstatus()
                }, 5000);
            }
        })
    };
    const gettaskstatus = () => {
        progress({
            task_id: task_id.value
        }).then(res=>{
            console.log(res);
            if(res.data.code == 0){
                tasklist.value = res.data.data
                // 轮询这个接口
                if(res.data.data.progress < 1){
                    setTimeout(() => {
                        gettaskstatus()
                    }, 5000);
                }
            }
        })
    }
    return {
      inputfile,
      imagebase,
      input,
      num,
      restore_faces,
      enable_hr,
      tasklist,
      task_id,
      change,
      aset,
    };
  },
});
