import request from '@/utils/request';
import qs from 'qs';
/**
 * 用户登录
 */
 export function login(data: any) {
    return request({
        url: 'login',
        method: 'get',
        // data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        params: data,
    })
}
/**
 * 个人账号登录
 */
 export function personloginApi(data: any) {
    return request({
        url: 'personlogin/',
        method: 'post',
        // data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        data: qs.stringify(data),
    })
}
/**
 * IP登录
 */
export function iplogin() {
    return request({
        url: 'iplogin/',
        method: 'get',
    })
}

/**
 * chatintel
 * @param {*} data
 */
export function chatintel(data: any) {
    return request({
        url: 'chatintel/fellows/',
        method: 'post',
        data,
    })
}


/**
 * chatintel
 * @param {*} query
 */
 export function chatinteldetail(query: any) {
    return request({
        url: 'chatintel/fellows/detail/',
        method: 'get',
        params: query,
    })
}

/**
 * chatresearch 文献搜索接口
 * @param {*} data 
 * @returns 
 */
export function chat_research(data: any) {
    return request({
        url: 'chatresearch/search/',
        method: 'post',
        data,
        // headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        // },
        // data: qs.stringify(data),
    })
}
/**
 * chatresearch 侧边聚合接口
 * @param {*} data 
 * @returns 
 */
export function sideBarListApi(data: any){
    return request({
        url:'chatresearch/aggs/',
        method:'post',
        data
    })
}


export function get_similar_question(data: any) {
    return request({
        url: 'chatlib/get_similar_question/',
        method: 'get',
        params: data,
    })
}
// 注册用户
export function organ_user(params: any) {
    return request({ 
      url: 'pdf/organ_user/',
      method: 'post',
      data:params
    })
}
// 子用户登录
export function organ_user_login(params: any) {
    return request({
      url: 'pdf/organ_user_login/',
      method: 'post',
      data:params
    })
}
