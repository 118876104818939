
import { defineComponent, ref, reactive } from "vue";
export default defineComponent({
  name: "Qr",
  components: {},
  setup() {
    var value = ref('')
    var othervalue = ref('')
    var options = reactive([
        "宇航学院",
        "机电学院",
        "机械与车辆学院",
        "光电学院",
        "信息与电子学院",
        "自动化学院",
        "计算机学院",
        "软件学院",
        "材料学院",
        "化工与环境学院",
        "生命学院",
        "理学院",
        "管理与经济学院",
        "人文与社会科学学院",
        "法学院",
        "外国语学院",
        "设计与艺术学院",
        "其他"
    ])
    var value2 = ref('')
    var options2 = reactive([
        "老师",
        "博士生",
        "研究生",
        "本科生"
    ])
    var threelist = ref([])
    var fourlist = ref([])
    var fivelist = ref([])
    var sixlist = ref([])
    var sevenlist = ref([])
    var eightlist = ref([])
    var textvalue = ref('')
    var disable = ref(true)
    const getline = () =>{
        //判断 value，value2，threelist，fourlist，fivelist，sixlist，sevenlist，eightlist 有几个填写了
        let num = 0
        let size = 100 / 8
        if(value.value){
            num++
        }
        if(value2.value){
            num++
        }
        if(threelist.value.length){
            num++
        }
        if(fourlist.value.length){
            num++
        }
        if(fivelist.value.length){
            num++
        }
        if(sixlist.value.length){
            num++
        }
        if(sevenlist.value.length){
            num++
        }
        if(eightlist.value.length){
            num++
        }
        if(num != 8){
            disable.value = true
        }else{
            disable.value = false
        }
        console.log(disable.value);
        
        textvalue.value = `width:${num * size}%;height:2px;background:#0095FF;`
    }
    return { 
        value,
        options,
        othervalue,
        textvalue,
        value2,
        options2,
        threelist,
        fourlist,
        fivelist,
        sixlist,
        sevenlist,
        eightlist,
        disable,
        getline

    };
  },
});
