import request from '@/utils/request3';
export function txt2img(data:any) {
    return request({
        url: 'getimg',
        method: 'post',
        data:data
    })
}
export function getqr(data:any) {
    return request({
        url: 'getqrbase',
        method: 'post',
        data:data
    })
}
export function getsdmodels() {
    return request({
        url: 'sd_models',
        method: 'get',
    })
}
export function progress() {
    return request({
        url: 'getprogress',
        method: 'get',
    })
}