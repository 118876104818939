import axios from 'axios'
const service = axios.create({
   baseURL: "/qrapi", 
    //  baseURL: window.location.origin + '/api/', // url = base url + request url
    // baseURL: "http://192.168.45.81:7861/", // url = base url + request url 
    // baseURL: "http://192.168.66.163:5004", // url = base url + request  url
    timeout: 5000000, // 请求超时时间
    withCredentials: true, // 允许携带cookie
})
service.interceptors.request.use((config) => {
    config.headers.Authorization ='Bearer '+ localStorage.getItem('token')
    return config
}, (err) => {
    // console.log('a1')
    return Promise.reject(err)
})

service.interceptors.response.use((res) => {
    // const response = res.data
    return res
}, (err) => {
    console.log('a')
    return Promise.reject(err)
})

export default service