import request from '@/utils/request2';

export function getmodellist() {
    return request({
        headers:{
            token:'gEW6Dma6FdDF3CvE'
        },
      url: 'sdapi/v1/sd-models',
      method: 'get',
    })
}

export function txt2img(data:any) {
    return request({
        headers:{
            token:'gEW6Dma6FdDF3CvE'
        },
        url: 'sdapi/v1/txt2img',
        method: 'post',
        data:data
    })
}