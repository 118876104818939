
import jsQR from 'jsqr';
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import { defineComponent, ref, reactive,onMounted } from "vue";
import { getqr,txt2img,getsdmodels,progress} from '@/api/research/qrpyindex'
import { ElMessage } from 'element-plus'
import type { UploadFile } from 'element-plus'
export default defineComponent({
    components:{
        Delete,
        Plus,
        ZoomIn
    },
    setup() {
        var qr_url =  ref('')
        var img_base = ref('')
        var img_loading = ref(false)
        var zinifood = ref('')
        var nowmodel_name = ref('')
        var imglist:any = reactive({
            list:[]
        })
        var uploadrefss = ref()
        var sdmodels:any = reactive({
            list:[]
        })
        var acindex = ref(0)
        const tabs = (index:any,name:any) =>{
            console.log(index,name)
            
            acindex.value = index
            nowmodel_name.value = name
        }
        const handleFileUpload = (event: any) => {
            const file = event.target.files[0];
            
           
        };
        const getsd_models = () =>{
            getsdmodels().then(res=>{
                sdmodels.list = res.data
            })
        }
        const getquimg = () => {
            getqr({
                qr_url: qr_url.value
            }).then(res=>{
                img_base.value = res.data.data.img_base64
            })
        }
        const submit = () =>{
            if(img_base.value == ''){
                ElMessage.error('请选择二维码')
                return
            }else if(img_loading.value == true){
                ElMessage.error('请等待二维码生成结束')
                return
            }
            if(zinifood.value == '' && sdmodels.list[acindex.value].model_name == '自拟食物'){
                ElMessage.error('请输入提示词')
                return
            }
            var data = {
                height:720,
                width:720,
                negative_prompt:sdmodels.list[acindex.value].negative_pormpt,
                prompt:sdmodels.list[acindex.value].prompt,
                steps:Number(sdmodels.list[acindex.value].steps),
                cfg_scale:Number(sdmodels.list[acindex.value].cfg_scale),
                sampler_index:sdmodels.list[acindex.value].sampling,
                override_settings:{
                    sd_model_checkpoint:sdmodels.list[acindex.value].model
                }, 
                alwayson_scripts:{
                    controlnet:{
                        args:[
                            {
                                // control_type:sdmodels.list[acindex.value].control_type,
                                model:sdmodels.list[acindex.value].control_model,
                                module:sdmodels.list[acindex.value].preprocessor,
                                weight:Number(sdmodels.list[acindex.value].weight),
                                resize_mode:2,
                                pixel_perfect:true,
                                guidance_start:Number(sdmodels.list[acindex.value].start_step),
                                guidance_end:Number(sdmodels.list[acindex.value].end_step),
                                input_image:img_base.value
                            }
                        ]
                    }
                }
            }
            if(sdmodels.list[acindex.value].model_name == '自拟食物'){
                data.prompt+= "("+zinifood.value+":1.1)"
            }
            img_loading.value = true
            var timer = setInterval(() => {
                if(img_loading.value == false){
                    clearInterval(timer)
                }else{
                    progress().then(res=>{
                        console.log(res);
                        if(res.data.current_image){
                            imglist.list = [res.data.current_image]
                        }
                    })
                }
            }, 500);
            txt2img(data).then(res=>{
                console.log(JSON.parse(res.data.info));
                img_loading.value = false
                imglist.list = res.data.images
            })
        }
        const filechange = (file:any) =>{
            const reader = new FileReader();
            reader.onload = async (event: ProgressEvent<FileReader>) => {
                const imageSrc = event.target?.result;
                if (typeof imageSrc === 'string') {
                const imageBitmap = await createImageBitmap(await fetch(imageSrc).then(response => response.blob()));
                const { width, height } = imageBitmap;

                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                if (context) {
                    canvas.width = width;
                    canvas.height = height;
                    context.drawImage(imageBitmap, 0, 0, width, height);
                    const imageData = context.getImageData(0, 0, width, height).data;

                    const qrCode = jsQR(imageData, width, height);

                    // 获取解析出的链接地址
                    if (qrCode) {
                        const url = qrCode.data;
                        qr_url.value = url
                        getquimg()
                    } else {
                        uploadrefss.value.clearFiles()
                        ElMessage.error('未能解析出二维码')

                    }
                }
                }
            };

            reader.readAsDataURL(file.raw);
        }
        const dialogImageUrl = ref('')
        const dialogVisible = ref(false)
        const disabled = ref(false)
        
        const handleRemove = (file: UploadFile) => {
            uploadrefss.value.clearFiles()
            img_base.value = ''
        }

        const handlePictureCardPreview = (file: UploadFile) => {
            dialogImageUrl.value = file.url!
            dialogVisible.value = true
        }

        const handleDownload = (file: UploadFile) => {
            console.log(file)
        }
        onMounted(() => {
            getsd_models()
        })
        return{
            handleFileUpload,
            img_base,
            sdmodels,
            acindex,
            submit,
            tabs,
            imglist,
            disabled,
            dialogVisible,
            dialogImageUrl,
            handleRemove,
            nowmodel_name,
            uploadrefss,
            img_loading,
            zinifood,
            handlePictureCardPreview,
            handleDownload,
            filechange
        }
    }
})
