import request from '@/utils/request1';

export function quwei_qr(params: any) {
    return request({
    headers:{
        token:'gEW6Dma6FdDF3CvE'
    },
      url: 'quwei_qr',
      method: 'post',
      data:params
    })
}
export function progress(data: any) {
    return request({
        headers:{
            token:'gEW6Dma6FdDF3CvE'
        },
        url: 'progress',
        method: 'get',
        params: data,
    })
}
